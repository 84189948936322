// UI框架
import React, { Component } from 'react';
// UI组件
import { Tooltip, message, Affix } from 'antd';
import { Icon, PtzControl } from 'libs';
// 业务组件
import plugin from 'plugin';
import { bindLifecycle } from 'utils/bindLifecycle';
import { LangConsumer } from 'utils/LangProvider';
import { clone, isZeroArray } from '../../../../common';
import _ from 'lodash';
// 样式
import './index.less';

const _name_ = 'VehicleRuleConfigurationPlugin';

class VehicleRuleConfigurationPluginComponent extends React.Component {
    /** 用来存放组件内状态 */
    constructor (props) {
        super(props);
        this.ComponentName = _name_;
        this.videoRef = React.createRef();
        this.state = {
            hidePluginFlag: false, //控件是否隐藏
        };
    }

    componentDidMount () {
        this.open();
    }

    componentDidActivate () {
        this.open();
    }

    componentWillUnmount () {
        this.leave();
    }

    /**
     * 打开视频
     * @param {Number} stream 码流
     * @param {Number} channel 通道
     * @param {Number} viewIndex 窗口号
     */
    open = (stream = 1, channel = 0, viewIndex = 0) => {
        plugin.open(stream, channel, viewIndex);
    };

    /**
     * 控件覆盖
     */
    cover = () => {
        plugin.cover(this.videoRef.current);
    };

    /**
     * 视频关闭
     */
    close = () => {
        plugin.close();
    };

    /**
     * 视频隐藏
     */
    hide = () => {
        plugin.hide();
    };

    /**
     * 离开页面触发
     * 1. 清空规则；2.销毁容器；3.取消监听；4.隐藏插件；5.停止拉流
     */
    leave = (channel) => {};

    /**
     * 当控件隐藏时，显示视频区默认图片
     * @param [Boolean][bool]  控件是否隐藏
     */
    showVideoDefaultImage = (bool) => {
        this.setState({hidePluginFlag : bool});
    }

    /**
     * 监听事件
     */
    addPluginEvent = () => {
        plugin.addEvent('pluginInstall', () => {
            setTimeout(() => {
                this.drawArea();
            }, 50);
        }); //登入后控件安装完成
    }

    render () {
        let {$t} = this.props;
        let {hidePluginFlag} = this.state;
        return (
            <div className={`_${_name_}_`}>
                <Affix offsetTop={64}>
                    <div className="video-wrap" ref={this.videoRef}>
                        {
                            hidePluginFlag && <div style = {{textAlign : 'center', marginTop : '30%'}}><Icon type = 'videoDefault'/></div>
                        }
                    </div>
                    <div className="aitoolsbar-warp"></div>
                </Affix>
            </div>
        );
    }
}

export default LangConsumer(bindLifecycle(VehicleRuleConfigurationPluginComponent));